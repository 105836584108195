import React, { Component } from "react"
import Page from "../../components/page"
import pageStyles from "../../components/page.module.css"
import "../../../public/css/modules.min.css"
import "../../../public/css/style.css"
import "../../../public/css/main.css"
import Swatches from "../../components/Swatches/Swatches"

export default class AlbumSwatches extends Component {
  constructor() {
    super()
  }
  render() {
    return (
      <Page
        title={"Album Swatches - PhotoTailors"}
        location={this.props.location}
      >
        <div className={pageStyles.section}>
          <Swatches category={"album"} />
        </div>
      </Page>
    )
  }
}
